// import getSymbolFromCurrency from 'currency-symbol-map';

function formatCurrency(n, curSymbol){
    var value = parseFloat(n);
    if(isNaN(value)){
        value = 0;
    }
    
    // return getSymbolFromCurrency(curSymbol) + ' ' + value.toLocaleString(undefined,{minimumFractionDigits:2, maximumFractionDigits:2});
    return curSymbol + ' ' + value.toLocaleString('en',{minimumFractionDigits:2, maximumFractionDigits:2});
}

export { formatCurrency };