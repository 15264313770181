import {
  IonContent,
  IonPage,
  IonTitle,
  IonCard,
  IonLabel,
  IonList,
  IonItem,
  IonTextarea,
  IonButton,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonRow,
  IonCol,
  IonImg,
  useIonViewDidEnter,
  IonLoading,
  // IonAlert,
  // IonFooter,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import React, { useState } from 'react';
import braintree from 'braintree-web';
import DropIn from 'braintree-web-drop-in-react';
import './Pay.css';

import { formatCurrency } from '../myFunc';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';

import GiftanoLogo from '../assets/images/giftano_white2.png';

const baseApiUrl = '/api/' + process.env.REACT_APP_PATH_PAYMENT;

const Pay: React.FC<any> = () => {
  const { payment_request } = useParams<{ payment_request: string }>();

  const [formData, setFormData] = useState<any>({
    reference_number: '',
    amount: 0,
    currency: 'SGD',
    giftano_notes: '',
    customer_notes: '',
    status: 'unpaid',
  });

  const [isFound, setIsFound] = useState<boolean>(false);
  const [errorStatus, setErrorStatus] = useState<any>({});

  const [modalState, setModalState] = useState<any>({
    isOpen: false,
  });

  const [loadingState, setLoadingState] = useState<any>({
    isOpen: false,
    message: 'Loading...',
  });

  const [alertState, setAlertState] = useState<any>({
    isOpen: false,
    header: '',
    message: '',
    buttons: [],
  });

  const [braintreeInstance, setBraintreeInstance] = useState<any>();
  //const [braintreeClientToken, setBraintreeClientToken] = useState<string>();
  const [braintreeDeviceData, setBraintreeDeviceData] = useState<string>();
  const [braintreeDropinOptions, setBraintreeDropinOptions] = useState<any>({});

  const loadPaymentRequest = () => {
    setLoadingState({
      isOpen: true,
      message: 'Loading Payment Request...',
    });
    fetch(baseApiUrl + 'paymentrequest/' + payment_request)
      .then((ret) => {
        setLoadingState({ ...loadingState, isOpen: false });
        //console.log('ret post', ret);
        ret.json().then((json) => {
          //console.log('json', json);
          if (json.code === 200) {
            setIsFound(true);
            // sukses
            const referenceNumber = json.data['reference_number'];
            const amount = parseFloat(json.data['amount']);
            const currency = json.data['currency'];
            const giftanoNotes = json.data['giftano_notes'];
            const customerNotes = json.data['customer_notes'];
            const paymentStatus = json.data['payment_status'];
            const paymentGateway = json.data['payment_gateway'];
            setFormData({
              reference_number: referenceNumber,
              amount: amount,
              currency: currency,
              giftano_notes: giftanoNotes,
              customer_notes: customerNotes,
              payment_status: paymentStatus,
              payment_gateway: paymentGateway,
            });
          } else {
            setIsFound(false);
            console.log('json', json);
            setErrorStatus({ code: json.code, message: json.message });
            // setAlertState({
            //   isOpen : true,
            //   header : 'ERROR',
            //   message : json.message,
            //   buttons : ['OK']
            // });
          }
        });
      })
      .catch((err) => {
        //console.log('error ', err);
        setLoadingState({ ...loadingState, isOpen: false });
        //setAlertState({isOpen: true, header: 'ERROR', message: 'Error Load Payment Request', buttons: ['OK']})
        setIsFound(false);
        setErrorStatus({ code: 0, message: 'FAILED TO FETCH PAYMENT' });
      });
  };

  useIonViewDidEnter(() => {
    loadPaymentRequest();
  });

  const onClickPay = async () => {
    //console.log("pay Clicked", formData);
    setLoadingState({
      isOpen: true,
      message: 'Loading Payment Details...',
    });
    const url =
      baseApiUrl +
      'paymentgateway-client-token/' +
      (formData.payment_gateway ? formData.payment_gateway : '');
    try {
      const ret = await fetch(url);
      //console.log('ret', ret);
      if (ret.ok) {
        const json = await ret.json();
        if (json.code === 200) {
          // setBraintreeClientToken(json.data.client_token);
          let btOpt: any = {
            authorization: json.data.client_token,
            googlePay: {
              merchantId: json.data.google_merchant_id,
              transactionInfo: {
                currencyCode: formData.currency,
                totalPriceStatus: 'FINAL',
                totalPrice: formData.amount,
              },
              cardRequirements: {
                billingAddressRequired: true,
              },
            },
            applePay: {
              displayName: 'Giftano',
              paymentRequest: {
                total: {
                  label: formData.reference_number,
                  amount: formData.amount,
                },
                requiredBillingContactFields: ['postalAddress'],
              },
            },
          };
          //delete apple pay if not SGD
          if (formData.currency !== 'SGD') {
            delete btOpt.applePay;
          }

          //apply options
          //console.log('bt options', btOpt)
          setBraintreeDropinOptions(btOpt);

          setModalState({ ...modalState, isOpen: true });
        } else {
          setAlertState({
            isOpen: true,
            header: 'Payment Failed',
            message:
              'Unfortunately there was an error while processing your payment. Please try again or contact us at <a href="mailto:support@giftano.com" target="_blank" rel="noopener noreferrer">support@giftano.com</a>', //json.message,
            buttons: ['OK'],
          });
        }
      } else {
        setAlertState({
          isOpen: true,
          header: 'Payment Failed',
          message:
            'Unfortunately there was an error while processing your payment. Please try again or contact us at <a href="mailto:support@giftano.com" target="_blank" rel="noopener noreferrer">support@giftano.com</a>', //json.message,
          buttons: ['OK'],
        });
      }
    } catch (err) {
      //console.log('error load form', err);
      setAlertState({
        isOpen: true,
        header: 'Payment Failed',
        message:
          'Unfortunately there was an error while processing your payment. Please try again or contact us at <a href="mailto:support@giftano.com" target="_blank" rel="noopener noreferrer">support@giftano.com</a>', //json.message,
        buttons: ['OK'],
      });
    }
    setLoadingState({ ...loadingState, isOpen: false });
  };

  const onBraintreeInstance = (i: any) => {
    //console.log('instance set', i);
    setBraintreeInstance(i);
    if (i && i._client) {
      braintree.dataCollector
        .create({
          client: i._client,
          kount: true,
          paypal: true,
        })
        .then((res) => {
          //console.log('result collector', res);
          setBraintreeDeviceData(res.deviceData);
        })
        .catch((err) => {
          //console.log('collector error', err);
        });
    }
  };

  const onClickPayConfirm = () => {
    //console.log('pay confirm click', braintreeInstance);
    setLoadingState({
      isOpen: true,
      message: 'Process Payment...',
    });
    braintreeInstance.requestPaymentMethod((err: any, payload: any) => {
      if (err) {
        //console.log('error found');
        setLoadingState({ ...loadingState, isOpen: false });
        return;
      }
      //console.log(err,payload, braintreeDeviceData);
      const url =
        baseApiUrl +
        'paymentgateway-transaction-with-payment-request-update/5eda4587e5aec85cd1a5e35c';
      fetch(url, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          amount: formData.amount,
          payment_method_nonce: payload.nonce,
          device_data: braintreeDeviceData,
          reference_number: formData.reference_number,
          customer_notes: formData.customer_notes,
        }),
      })
        .then((ret) => ret.json())
        .then((json) => {
          setLoadingState({ ...setLoadingState, isOpen: false });
          //console.log('json res', json);
          if (json.code === 201) {
            setModalState({ ...modalState, isOpen: false });
            loadPaymentRequest();
            setAlertState({
              isOpen: true,
              header: 'SUCCESS',
              message: 'Thank you for your payment',
              buttons: ['OK'],
            });
          } else {
            setAlertState({
              isOpen: true,
              header: 'Payment Failed',
              message:
                'Unfortunately there was an error while processing your payment. Please try again or contact us at <a href="mailto:support@giftano.com" target="_blank" rel="noopener noreferrer">support@giftano.com</a>', //json.message,
              buttons: ['OK'],
            });
          }
        })
        .catch((err) => {
          //console.log('error', err);
          setLoadingState({ ...setLoadingState, isOpen: false });
        });
    });
  };

  return (
    <IonPage>
      <IonContent className='with-bg'>
        <IonLoading
          isOpen={loadingState.isOpen}
          onDidDismiss={() =>
            setLoadingState({ ...loadingState, isOpen: false })
          }
          message={loadingState.message}
        />
        {/* <IonAlert
        isOpen={alertState.isOpen}
        onDidDismiss={()=>setAlertState({...alertState, isOpen: false})}
        header={alertState.header}
        message={alertState.message}
        buttons={alertState.buttons}
        /> */}
        <IonModal
          isOpen={alertState.isOpen}
          onWillPresent={() => setModalState({ ...modalState, isOpen: false })}
          onDidDismiss={() => setAlertState({ ...alertState, isOpen: false })}
          cssClass='modal-alert'
        >
          <IonHeader>
            <IonToolbar
              color={alertState.header === 'SUCCESS' ? 'primary' : 'warning'}
            >
              <IonTitle>{alertState.header}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <div className='modal-content ion-padding'>
            <div style={{ color: 'black', fontSize: '14pt' }}>
              {parse(alertState.message)}
            </div>
            <IonButton
              className='ion-margin-top'
              expand='block'
              onClick={() => setAlertState({ ...alertState, isOpen: false })}
            >
              OK
            </IonButton>
          </div>
        </IonModal>
        <IonCard>
          <IonRow>
            <IonCol
              size='auto'
              style={{ backgroundColor: 'var(--ion-color-primary)' }}
              className='side-col'
            >
              <div className='side-box'>
                <a href='https://giftano.com'>
                  <IonImg className='side-box-logo' src={GiftanoLogo}></IonImg>
                </a>
                <div className='side-box-footer'>
                  <p>
                    Giftano.com{' '}
                    <a
                      href='mailto:support@giftano.com'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      support@giftano.com
                    </a>
                  </p>
                  <p>
                    Giftano Pte Ltd 201433730C, One George Street Level 10
                    Singapore 049145
                  </p>
                </div>
              </div>
            </IonCol>
            <IonCol style={{ padding: '15px 30px' }}>
              <div className='payment-step1'>
                <h2 className='my-title'>Payment to Giftano</h2>
                {isFound ? (
                  <React.Fragment>
                    <IonList>
                      <IonItem>
                        <IonLabel>
                          <p>Reference Number</p>
                          <h2>{formData.reference_number}</h2>
                        </IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>
                          <p>Amount</p>
                          <h2>
                            {formatCurrency(formData.amount, formData.currency)}
                          </h2>
                        </IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel className='ion-text-wrap'>
                          <p>Giftano Notes</p>
                          <h2>{formData.giftano_notes}</h2>
                        </IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel position='stacked'>Customer Notes</IonLabel>
                        <IonTextarea
                          disabled={
                            formData.payment_status &&
                            formData.payment_status.toLowerCase() === 'paid'
                          }
                          placeholder='Type your note here...!'
                          value={formData.customer_notes}
                          onIonChange={(e) =>
                            setFormData({
                              ...formData,
                              customer_notes: e.detail.value,
                            })
                          }
                        ></IonTextarea>
                      </IonItem>
                    </IonList>
                    {formData.payment_status &&
                    formData.payment_status.toLowerCase() === 'paid' ? (
                      <h2 className='ion-text-center text-paid'>PAID</h2>
                    ) : (
                      <IonButton
                        size='large'
                        expand='block'
                        onClick={onClickPay}
                      >
                        PAY NOW
                      </IonButton>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <h1 className='err-code'>{errorStatus.code}</h1>
                    <h1 className='err-message'>{errorStatus.message}</h1>
                  </React.Fragment>
                )}
              </div>
            </IonCol>
          </IonRow>
        </IonCard>
        <IonModal
          isOpen={modalState.isOpen}
          onDidDismiss={() => setModalState({ ...modalState, isOpen: false })}
          cssClass='modal-step2'
        >
          <IonHeader>
            <IonToolbar color='primary'>
              <IonTitle>Confirm Payment</IonTitle>
              <IonButtons slot='end'>
                <IonButton
                  onClick={() =>
                    setModalState({ ...modalState, isOpen: false })
                  }
                >
                  <IonIcon icon={close} slot='icon-only'></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <div className='modal-content ion-padding'>
            <DropIn
              options={braintreeDropinOptions}
              onInstance={onBraintreeInstance}
            />
            <IonButton expand='block' onClick={onClickPayConfirm}>
              PAY NOW
            </IonButton>
          </div>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Pay;
